import React, { useState } from 'react';
import { Button, HeaderAlertsInfo, Typography } from 'components';
import { useNavigate } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { setIsNotificationData } from 'store/features/Auth';

import './index.scss';

export const NotificationContent = () => {
  const dispatch = useAppDispatch();
  const notificationData = useAppSelector((state: RootState) => {
    return state.auth.notificationData;
  });
  const isShowNotification = useAppSelector((state: RootState) => {
    return state.auth.isShowNotification;
  });
  const [showLess, setShowLess] = useState(true);

  const parseText = (text: string) => {
    const transform = (node: any) => {
      if (node.type === 'tag' && node.name === 'a') {
        return (
          <a href={node.attribs.href} target="_blank" rel="noopener noreferrer">
            {domToReact(node.children)}
          </a>
        );
      }
    };

    return parse(text || '', { replace: transform });
  };

  const smartText = (text: string, length = 250) => {
    if (text.length < length) {
      return (
        <Typography
          customClassName="notification-description-text"
          variant="smallRegular"
        >
          {parseText(text)}
        </Typography>
      );
    }

    return (
      <Typography
        customClassName="notification-description-text"
        variant="smallRegular"
      >
        {showLess ? parseText(text.slice(0, length)) : parseText(text)}

        <Button
          type="link"
          buttonLabelClassNames="see-more-button-label"
          onClick={() => setShowLess(!showLess)}
          label={showLess ? 'Les mer' : 'Lese mindre'}
        />
      </Typography>
    );
  };

  return (
    <div>
      {isShowNotification === true && (
        <HeaderAlertsInfo type="notification">
          <div className="notification-text-content">
            <Typography customClassName="notification-header-text" variant="h5">
              {notificationData.subject}
            </Typography>

            {notificationData?.description &&
              smartText(notificationData.description)}

            {/* <Typography
              customClassName="notification-description-text"
              variant="smallRegular"
            >
              {smartText(notificationData.description)}
            </Typography> */}
          </div>

          <div>
            <i
              className="ri-close-line modal-close-icon notification-close-icon"
              onClick={() => {
                dispatch(setIsNotificationData(false));
              }}
              role="presentation"
            />
          </div>
        </HeaderAlertsInfo>
      )}
    </div>
  );
};
